<template>
  <div class="panel-tag-autocomplete is-column">
    <div class="is-align-items-center is-full-width">
      <bc-autocomplete
        :keep-first="true"
        :limit="10"
        :options="matches"
        :is-scrollable="false"
        is-dropdown-full-width
        :disabled="isDisabled"
        @input="getSuggestions"
        @dropdown="onDropdown"
        @select="handleSelect"
        class="panel-tag-autocomplete__autocomplete is-full-width"
        :class="{ 'skills-autocomplete__disabled': isDisabled }"
        icon="tool"
        :label="showLabel ? $t('panel-coder-edit.skills') : undefined"
        name="skills-actual"
        :placeholder="$t(`generics.find-${tagTypeSlug}`)"
        v-model="searchTag">
        <template #empty>
          <bc-dropdown-item
            class="panel-tag-autocomplete__option font-size-s is-expanded"
            :text="$t(`toast.no-${tagTypeSlug}-found`)">
          </bc-dropdown-item>
        </template>
        <template #option="{ option }">
          <bc-dropdown-item
            :is-selected="isTagSelected(option._id)"
            :text="option.name"
            :info="getInfo(option)"
            class="panel-tag-autocomplete__option font-size-s is-expanded">
          </bc-dropdown-item>
        </template>
      </bc-autocomplete>
    </div>
    <div class="panel-tag-autocomplete__card-content-list" v-if="selectedTagsList.length">
      <div
        v-for="(item, index) in selectedTagsList"
        :key="index">
        <div>{{ item.name }}</div>
        <div>{{ `Profils : ${item.count.coders}` }}</div>
        <div>{{ `Jobs : ${item.count.jobs}` }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';
import BcAutocomplete from '@/ui-kit/components/BcAutocomplete/BcAutocomplete';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem';
import { searchTags } from '@/api/tags';
import { mapState } from 'vuex';

export default {
  name: 'panel-tag-autocomplete',
  components: {
    BcDropdownItem,
    BcAutocomplete,
  },
  mixins: [],
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    tagTypeSlug: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.selected != null && this.selected.length > 0) {
      this.selectedTagsList = this.uniqueArray(this.selected);
    }
  },
  data() {
    return {
      searchTag: '',
      isDisabled: false,
      tagsList: [],
      selectedTagsList: [],
      isDropdownOpen: false,
    };
  },
  computed: {
    matches() {
      return this.tagsList.filter(
        skill => skill.name.toLowerCase().indexOf(this.searchTag.toLowerCase()) === 0
      );
    },
    ...mapState({
      tagTypes: state => state.tags.tagTypes,
    }),
    tagTypeId() {
      const tagType = this.tagTypes.find((item) => item.slug === this.tagTypeSlug);
      return tagType ? tagType._id : null;
    }
  },
  methods: {
    getSuggestions: debounce(async function() {
      try {
        const { data } = await searchTags({ name: this.searchTag, limit: 5, tagTypeId: this.tagTypeId });
        const tagsIds = this.selectedTagsList.map(tag => tag._id);
        this.tagsList = data.filter(tag => !tagsIds.includes(tag._id));
      } catch (err) {
        this.$toast.show({
          type: 'error',
          message: err.message,
        });
      }
    }, 300),
    onDropdown(isOpen) {
      this.isDropdownOpen = isOpen;
    },
    handleSelect(tag) {
      if (this.isTagSelected(tag._id)) {
        this.removeTag(tag);
      } else {
        this.addTag(tag);
      }

      // Delete duplicate element 
      this.selectedTagsList = this.uniqueArray(this.selectedTagsList);
      this.$emit('select-tags', this.selectedTagsList);
      this.searchTag = '';
    },
    addTag(tag) {
      this.$emit('add-Tag', tag);

      this.selectedTagsList.push(tag);
    },
    removeTag(Tag) {
      this.$emit('remove-Tag', Tag);
      this.selectedTagsList = this.selectedTagsList.filter(s => s._id !== Tag._id);
    },
    removeLastTag() {
      if (this.searchTag !== '' || this.selectedTagsList.length === 0) {
        return;
      }
      const lastTag = this.selectedTagsList[this.selectedTagsList.length - 1] || {};
      this.removeTag(lastTag);
    },
    isTagSelected(id) {
      return this.selectedTagsList.findIndex(tag => tag._id.toString() === id.toString()) !== -1;
    },
    getInfo(tag) {
      return `Profils : ${tag.count.coders} - Jobs : ${tag.count.jobs}`;
    },
    uniqueArray(array) {
      return array.filter((item, index) => {
        return index === array.findIndex(obj => {
          return obj._id.toString() === item._id.toString();
        });
      });
    }
  },
  watch: {
    selected(newValue) {
      this.selectedTagsList = this.uniqueArray(newValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-tag-autocomplete {
  &__create_industry {
    width: 100%;
    flex-direction: column;
    position: absolute;
    background: white;
    border-radius: 5px;

    &-header {
      padding: 15px;
      border-bottom: 1px solid gray;
      width: 100%;

      & > p {
        width: 100%;
        text-align: center;
      }
    }

    &-content {
      flex-direction: column;
      padding: 15px;
    }
  }

  &__card-content {
      width: 100%;
      padding: 20px 40px;
      display: flex;
      flex-direction: column;

      &-list {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
      }

      &-list > div {
        margin: 5px 0px;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid lightgrey;
        border-radius: 5px;
        min-height: 40px;
      }

      &-list > div > div:first-child {
        width: 50%;
      }
  }

  &__autocomplete {
    margin-bottom: 10px;
  }

  &__option {
    line-height: 15px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__wrapper {
    margin: -5px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__tag {
    border-radius: 30px;
    margin: 2px;
    font-size: $font-size-xxs;
    font-weight: $font-weight-bold;
    background: #ecf1fa;

    &:hover {
      cursor: pointer;
    }
  }

  &__disabled {
    opacity: 0.6;
    &:hover {
      cursor: not-allowed;
    }
  }

  :deep() .bc-input__wrapper {
    display: flex;
    flex-wrap: wrap;
    min-height: 41px;
  }

  :deep() .bc-input__label--focus {
    top: -20px;
    left: 5px;
    transform: none;
  }
}
</style>
