import { http } from '@/api/index';

export const getTags = () => {
  return http.get('/tags');
};

export const searchTags = ({ name, page, visible, sortKey, sortValue, limit, verified, tagTypeId, _organization }) => {
  return http.get('/tags', {
    params: {
      name,
      visible,
      page,
      sortKey,
      sortValue,
      limit,
      verified,
      tagTypeId,
      _organization
    }
  });
};

export const countSearchTags = ({ name, visible, verified, tagTypeId, _organization }) => {
  return http.get('/tags/count', {
    params: {
      name,
      visible,
      verified,
      tagTypeId,
      _organization
    }
  });
};

export const getTagById = id => {
  return http.get(`/tags/${id}/details`);
};

export const getTagsAutocomplete = ({ name }) => {
  return http.get('/tags/autocomplete', {
    params: {
      query: name
    }
  })
}

export const getPersonalityValues = () => {
  return http.get('/tags/personalities-values');
};

export const getTagTypes = () => {
  return http.get(`/tags/types`);
};

export const createTag = ({ name }) => {
  return http.post('/tags', {
    name
  });
};

export const createTagAll = ({ name, _tagType }) => {
  return http.post('/tags/all', {
    name,
    _tagType
  });
};

export const createTagSkill = ({ name }) => {
  return http.post('/tags', {
    name,
  });
};

export const createTagProfession = ({ name }) => {
  return http.post('/professions', {
    name,
  });
};

export const editTag = ({ id, name, visible, verified, _organization }) => {
  return http.patch(`/tags/${id}`, {
    name,
    visible,
    verified,
    _organization
  });
};

export const replaceTag = ({ origin, target, tagTypeSlug }) => {
  return http.patch(`/tags/replace`, {
    origin,
    target,
    tagTypeSlug,
  });
};

export const pushTag = ({ origin, target, tagTypeSlug }) => {
  return http.patch(`/tags/push`, {
    origin,
    target,
    tagTypeSlug,
  });
};

export const deleteTag = id => {
  return http.delete(`/tags/${id}`);
};

export const getTagTypeLink = () => {
  return http.get('/tags/type/link');
};

export const createTagLink = ({ origin, target, typeLink }) => {
  return http.post('/tags/links', {
    origin,
    target,
    typeLink
  });
};

export const deleteTagLink = id => {
  return http.delete(`/tags/links/${id}`);
};

export const getOrganizationsWithTags = () => {
  return http.get('/tags/organizations');
}

export default {
  getTags,
  searchTags,
  countSearchTags,
  getPersonalityValues,
  createTag,
  editTag,
  deleteTag,
  deleteTagLink,
  getTagById,
  createTagLink,
  getTagTypeLink,
  getOrganizationsWithTags,
};
